





























































import { Component, Vue } from 'vue-property-decorator';

import { FieldState } from './model';
import ROUTES from '@/router/ROUTES';
import GeneralModule from '@/store/modules/general/GeneralModule';

import Input from '@/components/ui/input/Input.vue';
import Select from '@/components/ui/select/Select.vue';
import Button from '@/components/ui/button/Button.vue';
import HugeSwitch, { HugeSwitchOption } from '@/components/ui/huge-switch/';
import LanguageSelector from '@/components/ui/language-selector/LanguageSelector.vue';
import Checkbox from '@/components/ui/checkbox/Checkbox.vue';
import Logo from '@/assets/images/logo.svg';

@Component({
  name: 'FirstEnter',
  components: {
    Logo,
    Input,
    Select,
    Button,
    HugeSwitch,
    Checkbox,
    LanguageSelector,
  },
})
export default class Marks extends Vue {
  public brandNameField: FieldState = {
    value: '',
    isValid: null,
    validator: (v: string) => 0 < v.length,
  };
  public language = 'en';

  public get clientOptions(): Array<HugeSwitchOption> {
    return [
      {
        title: this.$t('General.Yes') as string,
        description: this.$t('FirstEnter.WeProvideServices') as string,
        value: true,
      },
      {
        title: this.$t('General.No') as string,
        description: this.$t('FirstEnter.WeMakeProducts') as string,
        value: false,
      },
    ];
  }

  public get directionOptions(): Array<HugeSwitchOption> {
    return [
      {
        title: this.$t('FirstEnter.OneDirection') as string,
        description: this.$t('FirstEnter.OneDirectionDescription') as string,
        value: true,
      },
      {
        title: this.$t('FirstEnter.MultipleDirections') as string,
        description: this.$t('FirstEnter.MultipleDirectionsDescription') as string,
        value: false,
      },
    ];
  }

  validate(field: FieldState) {
    field.isValid = field.validator(field.value);
  }

  logValue(v: unknown) {
    console.debug(v);
  }

  get canCreateCompany() {
    this.validate(this.brandNameField);

    return this.brandNameField.isValid;
  }

  async onSubmit(e: Event): Promise<void> {
    e.preventDefault();
    const request = GeneralModule.signupRequest;
    if (typeof this.brandNameField.value === 'string') {
      request.companyName = this.brandNameField.value;
    }
    GeneralModule.signupRequest = request;
    await GeneralModule.signup();

    this.$router.push(ROUTES.HOME);
  }
}
